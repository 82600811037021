import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const formatTitle = pathname => {
  const segments = pathname
    .split("/")
    .filter(Boolean)
    .slice(0, 2)
    .map(segment =>
      segment.replace(/-/g, " ").replace(/\b\w/g, char => char.toUpperCase())
    );
  return segments.join(" | ");
};

const TitleManager = () => {
  const location = useLocation();

  useEffect(() => {
    const title = formatTitle(location.pathname);
    document.title = title || "Default Title";
  }, [location.pathname]);

  return null;
};

export default TitleManager;
